<template>
  <section
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor}); 
  ${vars.sectionPaddingTopText ? `padding-top: ${vars.sectionPaddingTopText};` : ''}`"
  >
    <h2 v-if="titleHtml" v-html="titleHtml" class="tw-w-full tw-pt-24 lg:tw-pt-14" />

    <div class="tw-grid tw-w-full tw-grid-cols-12 tw-gap-4">
      <div
        class="tw-col-span-12"
        :class="{
          'lg:tw-col-span-7 xl:tw-col-span-8': sidebarComponents && sidebarComponents.length > 0,
        }"
      >
        <div v-if="vars.isSearchBoxEnabledBool" class="tw-mb-10 tw-w-full">
          <h3 class="tw-pb-6">SEARCH BLOGS</h3>
          <search-box
            v-model="keyword"
            :search-result-list="[]"
            :placeholder="searchBoxPlaceholder"
            :search-method="onSearched"
            version="v2"
          />
        </div>

        <div v-if="isBlogsLoading" style="height: 60dvh">
          <loader :loader-color="colors.brown" />
        </div>
        <div v-else-if="blogs && blogs.length === 0">
          <lazy-library-not-found :title="notFound.title" :description="notFound.description" />
        </div>

        <div v-else-if="!isBlogsLoading" class="tw-grid tw-grid-cols-12 tw-gap-x-3 tw-gap-y-3">
          <div
            v-for="(blog, index) in blogs"
            :key="`blog-${index}`"
            class="tw-col-span-12"
            :class="{
              'md:tw-col-span-6 lg:tw-col-span-12 xl:tw-col-span-6': sidebarComponents && sidebarComponents.length > 0,
              'md:tw-col-span-6 lg:tw-col-span-4': !sidebarComponents || sidebarComponents.length === 0,
            }"
          >
            <lazy-theme5-blogs-blog-card :blog="blog" :index="index" />
          </div>
        </div>
        <div v-if="isPaginationVisible">
          <lazy-library-blogs-pagination
            :component="component"
            version="v2"
            pagination-active-bg="var(--c-primary);"
            pagination-bg="var(--white);"
          />
        </div>
        <div v-if="!vars.isPaginationEnabledBool" class="tw-flex tw-w-full tw-items-center tw-justify-center tw-pt-4">
          <nuxt-link class="n-primary" :to="ROUTES.BLOGS" :external="true">View All Blogs</nuxt-link>
        </div>
      </div>
      <div
        v-if="sidebarComponents && sidebarComponents.length > 0"
        class="tw-col-span-12 lg:tw-col-span-5 xl:tw-col-span-4"
      >
        <div class="tw-mt-4">
          <theme5-available-components-for-sidebar
            key="blogs-page-sidebar"
            key-prefix="blogs-page-sidebar"
            :specific-components="sidebarComponents"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import type { Component } from '~/types';
import Loader from '~/components/common/Loader.vue';
import NotFound from '~/components/theme4/base/NotFound.vue';
import indexGlobal from '~/mixins/index.global';
import { BlogsMixin } from '~/units/blogs/mixins/blogs.mixin';
import NcButton from '~/components/common/inputs/NcButton.vue';
import { ComponentMixin } from '~/mixins/component.mixin';
import { useBlogsStore } from '~/units/blogs/store';
import SearchBox from '~/components/common/SearchBox.vue';
import { useWebsiteStore } from '~/units/website/store';
import { ROUTES } from '~/constants/ui.constants';

export default defineNuxtComponent({
  name: 'Theme5BlogsPage',
  components: { SearchBox, NcButton, NotFound, Loader },

  mixins: [BlogsMixin, indexGlobal, ComponentMixin],

  computed: {
    ...mapState(useWebsiteStore, ['theme', 'sidebarComponents']),

    isLatestCarouselEnabled() {
      return this.vars.isLatestCarouselEnabledNumber;
    },

    isLatestCarouselVisible() {
      const route = useRoute();
      return this.isLatestCarouselEnabled && this.blogs.length !== 0 && !route.query.q && !route.query.page;
    },

    isPaginationEnabled() {
      return this.vars.isPaginationEnabledBool;
    },

    isPaginationVisible() {
      return this.isPaginationEnabled && !this.isBlogsLoading;
    },

    variant() {
      return useVariant(this.component);
    },

    backgroundColor() {
      return getVariable(this.component, 'background-color');
    },

    titleHtml() {
      return this.vars.titleHtml;
    },

    ROUTES() {
      return ROUTES;
    },

    is() {
      return defineAsyncComponent(
        /* TODO: ~ didn't work when I used it. */
        () => import(`../AvailableComponentsForSidebar.vue`),
      );
    },
  },

  async setup(props: { component: Component }) {
    const blogsStore = useBlogsStore();
    await blogsStore.fetchBlogsInPage(props.component);
  },

  data() {
    return {
      variants: {
        v1: 'v1',
      },
      keyword: '',
      searchBoxPlaceholder: 'Search',
    };
  },

  mounted() {
    const route = useRoute();
    // TODO: Check where we are in blogs list or not
    if (route.query.q) {
      this.keyword = route.query.q as string;
    }
  },

  methods: {
    async onSearched() {
      const blogsStore = useBlogsStore();
      await blogsStore.searchBlogs(this.keyword);
    },
  },

  watch: {
    async $route(a, b) {
      if (a.query.q !== b.query.q) {
        await fetchBlogsManager(this.component, a.query && a.query.q ? a.query.q : null);
      }
    },
  },
});
</script>
